import Loadable from './ui-component/Loadable';
// routing
import Routes from './routes';
function App() {
  return (
    <Routes />
  );
}

export default App;
