import { useRoutes, Navigate, Link } from 'react-router-dom';
import Loadable from '../ui-component/Loadable';
import MinimalLayout from '../layout/MinimalLayout';

import { lazy } from 'react';
const Accueil = Loadable(lazy(() => import('../pages/accueil')));
const About = Loadable(lazy(() => import('../pages/about')));
export default function ThemeRoutes() {
    return useRoutes([
        {
            path: '/',
            element: <MinimalLayout />,
            children: [
                // Add your routes here
                {
                    path: '',
                    element: <Accueil />,
                },
                {
                    path: '*',
                    element: <Accueil />,
                },
                {
                    path: 'a-propos',
                    element: <About />,
                }
            ],
        },
    ])
}